import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import RecoveryCode from "../../components/RecoveryCode/RecoveryCode";
import { Link } from "react-router-dom";

import "../Welcome/Welcome.css";
import "../Signup/Signup.css";
import logoAppSmall from "../../images/logoAppSmall.png";

class PasswordRecovery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      email: "",
      recoveryCode: "",
      codeGenerated: false
    };
    this.onChange = this.onChange.bind(this);
    this.sendRecoveryCode = this.sendRecoveryCode.bind(this);
  }

  sendRecoveryCode(e) {
    e.preventDefault();
    if (this.state.email === "") {
      alert("digita el correo electrónico");
    } else if (!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(this.state.email)) {
      alert("el campo debe ser un correo electrónico válido");
    } else {
      //console.log("generate code");
      let code = "";
      for (var i = 0; i < 4; i++) {
        code += Math.floor(Math.random() * 10);
      }
      this.setState({ recoveryCode: code }, () => {
        //console.log(this.state.recoveryCode);
        PostData("passwordRecovery", this.state).then(result => {
          let responseJson = result;

          if (responseJson.userData) {
            localStorage.setItem("userData", JSON.stringify(responseJson));
            this.setState({ data: responseJson.userData });
            //console.log(this.state.data);
          } else {
            alert(result.error);
          }
        });
      });
      this.setState({ codeGenerated: true });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    let RecoveryCodeForm;
    if (this.state.codeGenerated) {
      RecoveryCodeForm = (
        <RecoveryCode
          recoveryCode={this.state.data.recoveryCode}
          email={this.state.data.mail}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="signup-container">
          <div className="img-desktop-container">
            <div className="img-desktop-wrap"></div>
          </div>
          <div className="signup-form-container">
            <div className="signup-header-container">
              <div className="logoApp">
                <img src={logoAppSmall} alt="Subasta Futbolera" />
              </div>
              <div className="signup-title">
                <h2>Recuperar Contraseña</h2>
              </div>
              <div className="signup-btn-container">
                <Link to={"/"} className="btn btn-primary-small">
                  REGRESAR
                </Link>
              </div>
            </div>
            <div className="signup-form">
              <input
                type="email"
                name="email"
                placeholder="Correo Electrónico"
                onChange={this.onChange}
                required
              />
              <input
                type="submit"
                className="btn btn-primary centered recovery-pass"
                value="RECUPERAR CONTRASEÑA"
                onClick={this.sendRecoveryCode}
              />
            </div>
            {RecoveryCodeForm}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PasswordRecovery;
