import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import { Redirect } from "react-router-dom";

import "../../components/Welcome/Welcome.css";
import "./Signup.css";
import logoAppSmall from "../../images/logoAppSmall.png";
import FacebookRegister from "../FacebookRegister/FacebookRegister";

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      name: "",
      country: "",
      redirectToReferrer: false,
    };
    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  signup() {
    if (this.state.password === this.state.confirmPassword) {
      if (
        this.state.username &&
        this.state.password &&
        this.state.email &&
        this.state.name
      ) {
        PostData("signup", this.state).then((result) => {
          let responseJson = result;
          if (responseJson.userData) {
            localStorage.setItem("userData", JSON.stringify(responseJson));
            if (localStorage.getItem("room")) {
              let roomURL = localStorage.getItem("room");
              localStorage.removeItem("room");
              window.location.href = roomURL;
            }
            this.setState({ redirectToReferrer: true });
          } else alert(result.error);
        });
      }
    } else {
      alert("error al confirmar el password");
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onChangeName(e) {
    this.setState(
      { username: e.target.value,
        name: e.target.value }
    );
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    if (this.state.redirectToReferrer || localStorage.getItem("userData")) {
      return <Redirect to={"/home"} />;
    }
    return (
      <React.Fragment>
        <div className="signup-container">
          <div className="signup-form-container">
            <div className="signup-header-container">
              <div className="logoApp">
                <img src={logoAppSmall} alt="Subasta Futbolera" />
              </div>
              <div className="signup-title">
                <h2>REGISTRO</h2>
              </div>
              <div className="signup-btn-container">
                <div className="btn btn-primary-small" onClick={this.goBack}>
                  REGRESAR
                </div>
              </div>
            </div>
            <div className="signup-form">
              <input
                type="text"
                name="name"
                placeholder="Nombre"
                onChange={this.onChangeName}
              />

              <input
                type="text"
                name="email"
                placeholder="Email"
                onChange={this.onChange}
              />
              <select name="country" onChange={this.onChange}>
                <option value="">Selecciona tu país...</option>
                <option value="Argentina">Argentina</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Brasil">Brasil</option>
                <option value="Chile">Chile</option>
                <option value="Colombia">Colombia</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cuba">Cuba</option>
                <option value="Ecuador">Ecuador</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Haití">Haití</option>
                <option value="Honduras">Honduras</option>
                <option value="México - CDMX">México - CDMX</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Panamá">Panamá</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Perú">Perú</option>
                <option value="República Dominicana">
                  República Dominicana
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Venezuela">Venezuela</option>
              </select>
              {/* <input
                type="text"
                name="username"
                placeholder="Nombre de Usuario"
                onChange={this.onChange}
              /> */}
              <div className="signup-password-container">
                <div className="signup-password-container-col50">
                  <input
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    onChange={this.onChange}
                  />
                </div>
                <div className="signup-password-container-col50">
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirma contraseña"
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="signup-accept-terms">
                <p>
                  Al registrarme acepto los &nbsp; 
                  <a
                    href="//subastafutbolera.com/terminos-y-condiciones"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    términos y condiciones
                  </a>
                </p>
              </div>
              <button
                className="btn btn-primary centered"
                onClick={this.signup}
              >REGISTRAR</button>
              <p>o regístrate con</p>
              <div className="center-button">
                <FacebookRegister />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Signup;
