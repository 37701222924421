import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./RecoveryCode.css";

class RecoveryCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      inputRecoveryCode: "",
      redirectToReferrer: false,
    };
    this.onChange = this.onChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }
  // componentDidUpdate() {
  //   let data = localStorage.getItem("userData");
  //   this.setState({ data: data });
  //   //console.log(data);
  // }
  onChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    window.history.replaceState("recovery", "subasta", `/recovery`);
    console.log(window.history.state);
    window.onpopstate = function (event) {
      window.history.go();
    };
  }

  changePassword(e) {
    if (this.state.inputRecoveryCode.length < 4) {
      alert("faltan digitos");
    } else if (this.state.inputRecoveryCode !== this.props.recoveryCode) {
      alert("código incorrecto");
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }

  render() {
    if (this.state.redirectToReferrer) {
      return (
        <Redirect
          to={{
            pathname: "/change-password",
            state: { email: this.props.email },
          }}
        />
      );
    } else {
      return (
        <React.Fragment>
          <div className="recovery-code-form-container">
            <p>
              Hemos enviado un código de recuperación a tu email, úsalo para
              reestablecer tu contraseña
            </p>
            <div className="recovery-code-form">
              <input
                type="text"
                name="inputRecoveryCode"
                maxLength="4"
                onChange={this.onChange}
              />
              <input
                type="submit"
                value="Validar código"
                className="btn btn-secondary centered"
                onClick={this.changePassword}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default RecoveryCode;
