import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Login from "../Login/Login";
import logoSubasta from "../../images/logoApp.png";

import "./Welcome.css";

class Welcome extends Component {
  componentDidMount() {
    console.log(localStorage.getItem("userData"));
  }

  render() {
    if (localStorage.getItem("userData")) {
      return <Redirect to={"/home"} />;
    }
    return (
      <React.Fragment>
        <div className="login-container">
          <div className="login-form-container">
            <div className="logo-container">
              <img src={logoSubasta} alt="" />
            </div>
            <div className="subasta-login-subtitle">Pollas & Quinielas</div>
            <div className="subasta-login-text">entre amigos</div>
            <Login />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Welcome;
