import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/PostData";

class FacebookRegister extends Component {
  state = {
    isLoggedIn: false,
    userID: "",
    name: "",
    email: ""
  };

  responseFacebook = response => {
    console.log(response);
    this.setState({
      isLoggedIn: true,
      userID: response.userID,
      name: response.name,
      email: response.email,
      redirectToReferrer: false
    });
  };

  registerFB() {
    if (this.state.email && this.state.userID) {
      console.log(this.state.email + " - " + this.state.userID);
      if (this.state.email && this.state.name) {
        PostData("signupFB", this.state).then(result => {
          let responseJson = result;
          if (responseJson.userData) {
            localStorage.setItem("userData", JSON.stringify(responseJson));
            if (localStorage.getItem("room")) {
              let roomURL = localStorage.getItem("room");
              localStorage.removeItem("room");
              window.location.href = roomURL;
            }
            this.setState({ redirectToReferrer: true });
          } else alert(result.error);
        });
      }
    } else {
      alert("error: acceso no válido");
    }
  }

  componentClicked = () => console.log("clicked");

  render() {
    let fbContent;
    if (!this.state.redirectToReferrer) {
      if (this.state.isLoggedIn) {
        this.registerFB();
      } else {
        fbContent = (
          <FacebookLogin
            appId="707116366379099"
            //autoLoad={true}
            fields="name,email"
            onClick={this.componentClicked}
            callback={this.responseFacebook}
            isMobile={true}
            disableMobileRedirect={true}
            cssClass="btn-facebook"
            icon="fa-facebook"
            textButton=""
          />
        );
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: "/finish-facebook-register",
            state: {
              name: this.state.name,
              userID: this.state.userID,
              email: this.state.email
            }
          }}
        />
      );
    }

    return <div>{fbContent}</div>;
  }
}

export default FacebookRegister;
