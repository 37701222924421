import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { PostData } from "../../services/PostData";

import "./ScoreOffering.css";

import tomyPopUp from "../../images/tomyPopUp.png";

const formatter = new Intl.NumberFormat("de-DE", {
  //style: "number",
  //currency: "COP",
  minimumFractionDigits: 0
});

class ScoreOffering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sku: this.props.sku,
      roomId: this.props.roomId,
      scoreOfferData: this.props.scoreOfferData,
      scoreOfferFor: this.props.scoreId,
      localTeam: this.props.localTeam,
      visitorTeam: this.props.visitorTeam,
      unity: this.props.unity,
      playerOffer: ""
    };

    this.cancelOffer = this.cancelOffer.bind(this);
    this.playerOffer = this.playerOffer.bind(this);
    this.increaseOffer = this.increaseOffer.bind(this);
    this.decreaseOffer = this.decreaseOffer.bind(this);
    this.setMyOffer = this.setMyOffer.bind(this);
    this.saveOffer = this.saveOffer.bind(this);
  }

  componentDidMount() {
    //console.log(this.state.sku);
    window.history.replaceState("home", "subasta", `/rooms/${this.state.sku}`);
    //console.log(window.history.state);
    window.onpopstate = function(event) {
      window.history.go();
    };
    this.playerOffer();
  }

  cancelOffer() {
    //console.log("cerrar oferta");
    window.history.go();
  }

  playerOffer() {
    let newOffer;
    let actualOffer = parseInt(this.state.scoreOfferData.offer);
    let unity = parseInt(this.state.unity);
    this.setState({ playerOffer: actualOffer + unity }, () => {
      newOffer = this.state.playerOffer;
    });
    return newOffer;
  }

  increaseOffer() {
    let actualPlayerOffer = parseInt(this.state.playerOffer);
    let unity = parseInt(this.state.unity);
    this.setState({ playerOffer: actualPlayerOffer + unity }, () => {
      console.log(`nueva oferta es: ${this.state.playerOffer}`);
    });
  }

  decreaseOffer() {
    let actualPlayerOffer = parseInt(this.state.playerOffer);
    let unity = parseInt(this.state.unity);
    let currentScoreOffer = parseInt(this.state.scoreOfferData.offer);
    let minimumOffer = currentScoreOffer + unity;
    if (this.state.playerOffer > minimumOffer) {
      this.setState({ playerOffer: actualPlayerOffer - unity }, () => {
        console.log(`nueva oferta es: ${this.state.playerOffer}`);
      });
    }
  }

  setMyOffer(e) {
    let myOffer = e.target.value;
    myOffer = parseInt(myOffer.split(".").join(""));
    this.setState({ playerOffer: myOffer });
  }

  saveOffer() {
    console.log("guardamos la oferta en la BD");
    let data = JSON.parse(localStorage.getItem("userData"));
    console.log("con estos datos de usuario: ", data.userData);
    let offerData = {};
    offerData.client_user_id = data.userData.id;
    offerData.roomId = this.state.roomId;
    offerData.cell = this.state.scoreOfferFor;
    offerData.bet = this.state.playerOffer;
    offerData.status = 1;
    console.log(" y con estos datos de la oferta: ", offerData);
    PostData("saveOffer", offerData).then(result => {
      let responseJson = result;
      console.log(responseJson.offerUpdated);
      if (responseJson.offerUpdated === 1) {
        console.log('datos almacenados en la base de datos');
        window.history.go();
      } else {
        alert(result.error);
      }
    });
  }

  render() {
    let offerMinimumValueError;         
    let offerMultipleValueError;
    let scoreOfferFor = this.state.scoreOfferFor;
    let localScore = scoreOfferFor.substr(1, 1);
    let visitorScore = scoreOfferFor.substr(3, 1);
    let unity = parseInt(this.state.unity);
    console.log(this.state.scoreOfferData.offer);
    let offer = Number(this.state.scoreOfferData.offer);
    let playerOffer = this.state.playerOffer;
    let offerButtonActive, offerButtonDisabled;
    let enableOfferButon = true;
    console.log(playerOffer);
    if (offer >= playerOffer) {
      offerMinimumValueError = (
        <div className="subasta-scoreOffering-error">
          ¡El valor de tu oferta debe ser mayor a{" "}
          {formatter.format(this.state.scoreOfferData.offer)}!
        </div>
      );
      enableOfferButon = false;
    }
    if (playerOffer % unity !== 0) {
      offerMultipleValueError = (
        <div className="subasta-scoreOffering-error">
          ¡tu oferta debe ser un mútiplo de {formatter.format(this.state.unity)}
          !
        </div>
      );
      enableOfferButon = false;
    }
    if (enableOfferButon) {
      offerButtonActive = (
        <div className="btn btn-primary centered" onClick={this.saveOffer}>
          Ofertar
        </div>
      );
    } else {
      offerButtonDisabled = (
        <div className="btn btn-primary centered disabled">Ofertar</div>
      );
    }

    const numberMask = createNumberMask({
      prefix: "",
      suffix: "",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ".",
      allowDecimal: true,
      decimalSymbol: ",",
      decimalLimit: 2, // how many digits allowed after the decimal
      integerLimit: 7, // limit length of integer numbers
      allowNegative: false,
      allowLeadingZeroes: false
    });
    console.log(this.props);

    return (
      <React.Fragment>
        <div className="subasta-scoreOffering-wrapper">
          <div className="subasta-scoreOffering-container">
            <div className="subasta-scoreOffering-btn-container">
              <div className="btn btn-danger-small centered">
                <div onClick={this.cancelOffer}>X</div>
              </div>
            </div>
            <div className="subasta-scoreOffering-avatar-container">
              <img src={tomyPopUp} alt="" />
            </div>
            <h1>¿Quién da más?</h1>
            <div className="subasta-scoreOffering-score-container">
              <div className="subasta-scoreOffering-score-teams">
                <div className="subasta-scoreOffering-score-teamName">
                  <h2>{this.state.localTeam}</h2>
                </div>
                <div className="subasta-scoreOffering-score-teamName">
                  <h2>{this.state.visitorTeam}</h2>
                </div>
              </div>
              <div className="subasta-scoreOffering-scores">
                <div className="subasta-scoreOffering-score">
                  <h2>{localScore}</h2>
                </div>
                <div className="subasta-scoreOffering-score">
                  <h2>{visitorScore}</h2>
                </div>
              </div>
            </div>
            <div className="subasta-scoreOffering-actualOffer">
              <div className="subasta-scoreOffering-actualOffer-value">
                Oferta actual:
                <br />
                <span>{formatter.format(offer)}</span>
              </div>
              <div className="subasta-scoreOffering-actualOffer-playerName">
                <span>{this.state.scoreOfferData.player}</span>
              </div>
            </div>
            <div className="separator"></div>
            <div className="subasta-scoreOffering-myOffer">Mi Oferta:</div>
            <div className="subasta-scoreOffering-offer-form-container">
              <div
                className="subasta-scoreOffering-offer-button"
                onClick={this.decreaseOffer}
              >
                -
              </div>
              <MaskedInput
                mask={numberMask}
                className="subasta-scoreOffering-offer-input"
                value={playerOffer}
                guide={false}
                id="my-input-id"
                onBlur={() => {}}
                onChange={this.setMyOffer}
              />
              <div
                className="subasta-scoreOffering-offer-button"
                onClick={this.increaseOffer}
              >
                +
              </div>
            </div>
            {offerMinimumValueError}
            {offerMultipleValueError}
            <p>
              Unidad de aumento: <span>{formatter.format(unity)}</span>
            </p>

            {offerButtonDisabled}
            {offerButtonActive}
            <p className="subasta-scoreOffering-info">
              ¡Quédate con este marcador mejorando la oferta!
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScoreOffering;
