import React, { Component } from "react";

import "./Loader.css";
import tomyPopUp from "../../images/tomyPopUp.png";

class Loader extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="subasta-loader-container">
          <div className="subasta-loading-container">
            <div className="subasta-loading-img">
              <img src={tomyPopUp} alt="Preparando el terreno de juego" />
            </div>
            <div className="subasta-loading-text">
              <h3>Preparando el terreno de juego...</h3>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Loader;
