import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./Home.css";
import { PostData } from "../../services/PostData";
import "../../styles/react-confirm-alert.css";
import NavSup from "../NavSup/NavSup";
import NavInf from "../NavInf/NavInf";
import AddSystem from "../AddSystem/AddSystem";
import RoomsList from "../RoomsList/RoomsList";
import CreateRoomMenuComponent from "../CreateRoomMenuComponent/CreateRoomMenuComponent";

import createRoomIcon from "../../images/subasta-create-room-icon.png";
//import searchRoomIcon from "../../images/subasta-search-room-icon.png";
import KeyIcon from "../../images/subasta-key-icon.png";
import ShopingCartUserCard from "../../images/shoping-cart-user-card.png";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      userFeed: "",
      redirectToReferrer: false,
      name: "",
      userId: "",
      viewCreateRoomsMenu: false,
      userRanking: "",
      userRankingList: {},
    };

    //this.getUserFeed = this.getUserFeed.bind(this);
    this.feedUpdate = this.feedUpdate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.deleteFeed = this.deleteFeed.bind(this);
    this.deleteFeedAction = this.deleteFeedAction.bind(this);
    this.logout = this.logout.bind(this);
    this.openCreateRoomMenuComponent = this.openCreateRoomMenuComponent.bind(
      this
    );
    //this.getUserRanking = this.getUserRanking.bind(this);
  }

  componentWillMount() {
    if (localStorage.getItem("userData")) {
      let data = JSON.parse(localStorage.getItem("userData"));
      this.setState({
        name: data.userData.name,
        userId: data.userData.id,
        changePass: data.userData.change_pass,
      });
      //let data = localStorage.getItem("userData");
      console.log(data);
    } else {
      this.setState({ redirectToReferrer: true, name: this.data.name });
    }
  }

  // componentDidMount() {
  //   this.getUserRanking();
  // }

  feedUpdate(e) {
    e.preventDefault();
    let data = JSON.parse(localStorage.getItem("userData"));
    let postData = {
      user_id: data.userData.user_id,
      feed: this.state.userFeed,
    };
    if (this.state.userFeed) {
      PostData("feedUpdate", postData).then((result) => {
        let responseJson = result;
        this.setState({ data: responseJson.feedData });
      });
    }
  }

  // getUserRanking() {
  //   let postData = {
  //     user_id: this.state.userId,
  //     name: this.state.name,
  //   };
  //   PostData("getUserRanking", postData).then(result => {
  //     let responseJson = result;
  //     console.log(responseJson);

  //     let rankingList = responseJson.rankingList;

  //     console.log('Ranking List =', rankingList);

  //     function compare(a,b) {
  //       const winningQuantityA = a.friend_winning_matchs;
  //       const winningQuantityB = b.friend_winning_matchs;
  //       let comparison = 0;
  //       if (winningQuantityA > winningQuantityB) {
  //         comparison = -1;
  //       } else if (winningQuantityA < winningQuantityB) {
  //         comparison = 1;
  //       }
  //       return comparison;
  //     }
  //     //this.setState({userRanking : rankingData[0]}, ()=>console.log(this.state.userRanking));

  //     rankingList.sort(compare);
  //     this.setState({userRankingList : {...rankingList}}, ()=> {
  //       console.log('Ranking List Ordered =', this.state.userRankingList);
  //     })

  //     console.log('mi ranking es: ', rankingList.findIndex(obj => obj.friend_id === this.state.userId) + 1); // +1 to get the rankign list from 1 instead 0
  //     this.setState({userRanking: rankingList.findIndex(obj => obj.friend_id === this.state.userId) + 1}, ()=>console.log('mi ranking es: ', this.state.userRanking));
  //   });

  // }

  deleteFeed(e) {}

  deleteFeedAction(e) {
    let updateIndex = e.target.getAttribute("value");

    let feed_id = document.getElementById("del").getAttribute("data");

    let data = JSON.parse(localStorage.getItem("userData"));

    let postData = { user_id: data.userData.user_id, feed_id: feed_id };
    if (postData) {
      PostData("feedDelete", postData).then((result) => {
        this.state.data.splice(updateIndex, 1);
        this.setState({ data: this.state.data });

        if (result.success) {
          alert(result.success);
        } else alert(result.error);
      });
    }
  }

  editFeed(e) {
    alert("j");
  }

  // getUserFeed() {
  //   let data = JSON.parse(localStorage.getItem("userData"));
  //   this.setState({ name: data.userData.name });
  //   let postData = { user_id: data.userData.user_id };

  //   if (data) {
  //     PostData("feed", postData).then(result => {
  //       let responseJson = result;
  //       if (responseJson.feedData) {
  //         this.setState({ data: responseJson.feedData });
  //         console.log(this.state);
  //       }
  //     });
  //   }
  // }

  onChange(e) {
    this.setState({ userFeed: e.target.value });
  }
  logout() {
    localStorage.setItem("userData", "");
    localStorage.clear();
    this.setState({ redirectToReferrer: true });
  }

  openCreateRoomMenuComponent(e) {
    var body = document.getElementsByClassName("subasta-dashboard-container");
    body[0].style.overflow = "hidden";
    this.setState({ viewCreateRoomsMenu: true });
  }

  render() {
    console.log(this.state.data);
    if (this.state.changePass === "1") {
      return <Redirect to={"/recovery"} />;
    }
    let viewCreateRoomsMenu = this.state.viewCreateRoomsMenu;
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/"} />;
    }

    let userRanking = localStorage.getItem("userRanking");
    console.log(userRanking);

    return (
      <React.Fragment>
        <div className="subasta-dashboard-container">
          <NavSup />
          <AddSystem />
          <h1 className="home-title">Mi Perfil</h1>
          <div className="subasta-user-section">
            <div className="subasta-create-room-btn-container desktop">
              <div className="create-room-btn">
                <img
                  src={createRoomIcon}
                  alt=""
                  onClick={this.openCreateRoomMenuComponent}
                />
              </div>
            </div>
            <div className="subasta-user-card-container">
              <div className="subasta-user-card-first-row">
                <div className="subasta-user-card-text">{this.state.name}</div>
                <div className="subasta-user-ranking">
                  <div className="subasta-user-card-text">Ranking:</div>
                  <div className="subasta-user-card-ranking-value">
                    {userRanking}
                  </div>
                </div>
              </div>
              <div className="subasta-user-card-second-row">
                <div className="subasta-key-icon-container">
                  <img src={KeyIcon} alt="" />
                </div>
                <div className="subasta-user-card-key-ammount">0</div>
                <div className="subasta-user-card-btn-container">
                  <div className="subasta-user-card-key-btn">
                    Comprar llaves
                  </div>
                  <div className="subasta-user-card-key-btn-icon">
                    <img src={ShopingCartUserCard} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {viewCreateRoomsMenu ? (
            <div className="subasta-createRoomsMenu-container">
              <CreateRoomMenuComponent />
            </div>
          ) : (
            console.log("invisible")
          )}
          <RoomsList />
          <NavInf />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
