import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PostData } from "../../services/PostData";
import Swal from "sweetalert2";

import "./NavSup.css";
import subastaNavBarLogo from "../../images/logo-subasta-blanco.png";
import menuIcon from "../../images/menuIcon.png";
import tomyPopUp from "../../images/tomyPopUp.png";

class NavSup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      showMenu: false,
      showRanking: false,
    };
    this.logout = this.logout.bind(this);
    this.openRanking = this.openRanking.bind(this);
    this.getUserRanking = this.getUserRanking.bind(this);
    this.showUpdateHelp = this.showUpdateHelp.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("userData")) {
      let data = JSON.parse(localStorage.getItem("userData"));
      this.setState(
        { name: data.userData.name, userId: data.userData.id },
        () => {
          console.log(data.userData.name);
          this.getUserRanking();
        }
      );
    }
  }

  logout() {
    localStorage.setItem("userData", "");
    localStorage.clear();
    this.setState({ redirectToReferrer: true });
  }

  getUserRanking() {
    let postData = {
      user_id: this.state.userId,
      name: this.state.name,
    };
    PostData("getUserRanking", postData).then((result) => {
      let responseJson = result;
      console.log(responseJson);

      let rankingList = responseJson.rankingList;

      console.log("Ranking List =", rankingList);

      function compare(a, b) {
        const winningQuantityA = a.friend_winning_matchs;
        const winningQuantityB = b.friend_winning_matchs;
        let comparison = 0;
        if (winningQuantityA > winningQuantityB) {
          comparison = -1;
        } else if (winningQuantityA < winningQuantityB) {
          comparison = 1;
        }
        return comparison;
      }
      //this.setState({userRanking : rankingData[0]}, ()=>console.log(this.state.userRanking));

      rankingList.sort(compare);

      const simplefiedRankingList = rankingList.reduce((acc, current) => {
        const x = acc.find((item) => item.friend_id === current.friend_id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      console.log(simplefiedRankingList);

      this.setState({ userRankingList: { ...simplefiedRankingList } }, () => {
        console.log("Ranking List Ordered =", this.state.userRankingList);
      });

      console.log(
        "mi ranking es: ",
        rankingList.findIndex((obj) => obj.friend_id === this.state.userId) + 1
      ); // +1 to get the rankign list from 1 instead 0
      this.setState(
        {
          userRanking:
            simplefiedRankingList.findIndex(
              (obj) => obj.friend_id === this.state.userId
            ) + 1,
        },
        () => console.log("mi ranking es: ", this.state.userRanking)
      );
      let userRanking = this.state.userRanking;
      localStorage.setItem("userRanking", userRanking);
    });
  }

  openRanking() {
    this.setState({ showRanking: true });
  }

  showUpdateHelp() {
    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 85,
      imageHeight: 130,
      title: "Actualizar APP",
      text:
        "Para que siempre tengas la última versión de Subasta Futbolera, borra el cache de tu navegador y recarga la página!",
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  render() {
    let displayRankingList;
    if (this.state.showRanking) {
      let rankingList = { ...this.state.userRankingList };
      console.log("el ranking es: ", rankingList);
      let rankingListHtml = [];
      let position = 1;
      for (const prop in rankingList) {
        console.log(`rankingList.${prop} = ${rankingList[prop].friend_name}`);
        rankingListHtml.push(
          <React.Fragment>
            <div className="rankingRow">
              <div className="positionNumber">{position}. </div>
              <div className="nameText"> {rankingList[prop].friend_name} </div>
              <div className="winningMatchNumber">
                {rankingList[prop].friend_winning_matchs}
              </div>
            </div>
          </React.Fragment>
        );
        position++;
      }
      displayRankingList = (
        <div className="rankingListContainer">
          <div className="ranking-closeBtn">
            <div
              className="closeBtn"
              onClick={() => {
                this.setState({
                  showMenu: !this.state.showMenu,
                  showRanking: !this.state.showRanking,
                });
              }}
            >
              X
            </div>
          </div>
          <h1>RANKING</h1>
          <div className="rankingList">
            <div className="ranking-headings">
              <div className="position-title">POS</div>
              <div className="name-title">Nombre</div>
              <div className="winningMatchs-title">Partidos Ganados</div>
            </div>
            <div className="ranking-data">{rankingListHtml}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="subasta-nav-sup-container">
        <div className="subasta-nav-sup-logo">
          <img src={subastaNavBarLogo} alt="" />
        </div>

        <div className="subasta-nav-sup-icons-container">
          <div className="subasta-nav-sup-icon-container">
            <div
              onClick={() => {
                this.setState({ showMenu: !this.state.showMenu });
              }}
            >
              <img src={menuIcon} alt="Ayuda" />
            </div>
            {this.state.showMenu ? (
              <div className="subastaMenu-wrap">
                <div className="subastaMenu">
                  <div className="subastaMenu-container">
                    <div className="subastaMenu-closeBtn">
                      <div
                        className="closeBtn"
                        onClick={() => {
                          this.setState({ showMenu: !this.state.showMenu });
                        }}
                      >
                        X
                      </div>
                    </div>
                    <div className="subastaMenu-link">
                      <Link
                        to="/home"
                        onClick={() => {
                          this.setState({ showMenu: !this.state.showMenu });
                        }}
                      >
                        HOME
                      </Link>
                    </div>
                    <div className="subastaMenu-link">
                      <a
                        href="https://subastafutbolera.com/instrucciones"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        INSTRUCCIONES
                      </a>
                    </div>
                    <div className="subastaMenu-link">
                      <button onClick={this.openRanking}> MI RANKING</button>
                      <div>{displayRankingList}</div>
                    </div>
                    <div className="subastaMenu-link">
                      <a
                        href="https://subastafutbolera.com/shop"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TIENDA
                      </a>
                    </div>
                    <div className="subastaMenu-link">
                      <a
                        href="https://subastafutbolera.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SITIO WEB
                      </a>
                    </div>
                    <div className="subastaMenu-link">
                      <a
                        href="https://subastafutbolera.com/terminos-y-condiciones"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TÉRMINOS Y CONDICIONES
                      </a>
                    </div>
                    <div className="subastaMenu-link">
                      <button onClick={this.showUpdateHelp}>
                        ACTUALIZAR APP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="desktop">
            <Link to="/" onClick={this.logout} className="logout">
              Salir
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NavSup;
