import React, { Component } from "react";
import RoomCard from "../RoomCard/RoomCard";
import PlayingRoomCard from "../PlayingRoomCard/PlayingRoomCard";

import { PostData } from "../../services/PostData";

import "./RoomsList.css";

class RoomsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      userFeed: "",
      redirectToReferrer: false,
      name: "",
      roomsByPlayerData: [],
      roomsByPlayerDataFiltered: [],
    };

    this.getUserFeed = this.getUserFeed.bind(this);
    this.getRoomsByPlayer = this.getRoomsByPlayer.bind(this);
    // this.feedUpdate = this.feedUpdate.bind(this);
    // this.onChange = this.onChange.bind(this);
    // this.deleteFeed = this.deleteFeed.bind(this);
    // this.deleteFeedAction = this.deleteFeedAction.bind(this);
    // this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("userData")) {
      this.getUserFeed();
    } else {
      this.setState({ redirectToReferrer: true, name: this.data.name });
    }
  }
  getUserFeed() {
    let data = JSON.parse(localStorage.getItem("userData"));
    this.setState({ name: data.userData.name });
    let postData = { user_id: data.userData.id };
    console.log(postData);
    if (data) {
      PostData("feed", postData).then((result) => {
        let responseJson = result;
        if (responseJson.feedData) {
          this.setState({ data: responseJson.feedData });
          console.log(this.state.data);
        }
      });
    }
    this.getRoomsByPlayer();
  }

  getRoomsByPlayer() {
    let data = JSON.parse(localStorage.getItem("userData"));
    this.setState({ name: data.userData.name });
    let postData = { user_id: data.userData.id };
    console.log(postData);
    if (data) {
      PostData("getRoomsByPlayer", postData).then((result) => {
        let responseJson = result;
        if (responseJson.roomByPlayerData) {
          this.setState({ roomsByPlayerData: responseJson.roomByPlayerData });
        }
        console.log("Salas donde juego:", this.state.roomsByPlayerData);
        const roomsByPlayerData = this.state.roomsByPlayerData;
        const roomsByPlayerDataArray = Object.values(roomsByPlayerData);
        this.setState(
          { roomsByPlayerDataFiltered: roomsByPlayerDataArray },
          () => console.log(this.state.roomsByPlayerDataFiltered)
        );
      });
    }
  }

  render() {
    console.log(this.state.data);
    const roomDataArray = this.state.data;
    let renderRoomCards;
    if (roomDataArray.length > 0) {
      renderRoomCards = roomDataArray.map((roomcard, i) => {
        console.log(roomcard);
        return <RoomCard roomData={roomcard} />;
      });
    } else {
      renderRoomCards = (
        <React.Fragment>
          <h3>Crea tu primera sala y diviertete</h3>
        </React.Fragment>
      );
    }

    const roomByPlayerList = this.state.roomsByPlayerDataFiltered;
    console.log(roomByPlayerList);
    let renderPlayingRoomCards;
    if (roomByPlayerList.length > 0) {
      renderPlayingRoomCards = roomByPlayerList.map((roomId, i) => {
        console.log(roomId);
        return <PlayingRoomCard roomId={roomId} />;
      });
    } else {
      renderPlayingRoomCards = (
        <React.Fragment>
          <h3>no tienes salas invitado</h3>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h1 className="home-title">Mis Salas</h1>
        <div className="subasta-roomslist-container">{renderRoomCards}</div>
        <h1 className="title">Salas Invitado</h1>
        <div className="subasta-invited-roomslist-container">
          {renderPlayingRoomCards}
        </div>
      </React.Fragment>
    );
  }
}

export default RoomsList;
