import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { PostData } from "../../services/PostData";
import "./Login.css";
import Facebook from "../Facebook/Facebook";
import registerIcon from "../../images/registerIcon.png";
import excalamationIcon from "../../images/exclamationIcon.png";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
    };

    this.login = this.login.bind(this);

    this.onChange = this.onChange.bind(this);

    this.goRegister = this.goRegister.bind(this);
  }

  login() {
    if (this.state.email && this.state.password) {
      console.log(this.state.email + " - " + this.state.password);
      PostData("login", this.state).then((result) => {
        let responseJson = result;

        if (responseJson.userData) {
          localStorage.setItem("userData", JSON.stringify(responseJson));
          if (localStorage.getItem("room")) {
            let roomURL = localStorage.getItem("room");
            localStorage.removeItem("room");
            window.location.href = roomURL;
          }
          this.setState({ redirectToReferrer: true });
        } else {
          alert(result.error);
        }
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  goRegister(e) {
    return <Redirect to={"/signup"} />;
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/home"} />;
    }

    if (localStorage.getItem("userData")) {
      return <Redirect to={"/home"} />;
    }

    return (
      <div className="login-form">
        <div className="subasta-separator-title">
          - si no tienes cuenta aún -
        </div>
        <button className="btn btn-secondary centered">
          <img src={registerIcon} alt="Registrar" />
          <Link className="registerLink" to={"/signup"}>
            REGÍSTRATE AQUÍ
          </Link>
        </button>
        <div className="subasta-separator-title">
          - si ya tienes cuenta, ingresa -
        </div>
        <input
          type="email"
          name="email"
          placeholder="Correo Electrónico"
          onChange={this.onChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Contraseña"
          onChange={this.onChange}
        />
        <input
          type="submit"
          value="INGRESAR"
          className="btn btn-primary centered"
          onClick={this.login}
        />
        <p>
          <Link to={"/recovery"}>
            <img src={excalamationIcon} alt="Olvidé mi contraseña" /> Olvidé mi
            contraseña
          </Link>
        </p>
        <div className="subasta-separator-title">- o ingresa con -</div>
        <div className="center-button">
          <Facebook />
        </div>
      </div>
    );
  }
}

export default Login;
