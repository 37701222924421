import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import Loader from "../Loader/Loader";
import NavSup from "../NavSup/NavSup";
import AddSystem from "../AddSystem/AddSystem";
import ScoreOffering from "../ScoreOffering/ScoreOffering";
import RoomReport from "../RoomReport/RoomReport";

import "./Room.css";

import roomIconDate from "../../images/roomIconDate.png";
import roomIconShare from "../../images/roomIconShare.png";
import roomIconUsers from "../../images/roomIconUsers.png";
import roomIconReports from "../../images/roomIconReports.png";
//import roomIconHeatMap from "../../images/roomIconHeatMap.png";
import subastaHelpIcon from "../../images/subastaHelpIcon.png";
import roomIconReportsAlert from "../../images/roomIconReportsAlert.png";
import tomyPopUp from "../../images/tomyPopUp.png";
import ayudaSalaSubasta from "../../images/ayudaSalaSubasta.jpg";

const formatter = new Intl.NumberFormat("de-DE", {
  //style: "number",
  //currency: "COP",
  minimumFractionDigits: 0,
});

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewRoom: false,
      roomIsFull: false,
      redirectToReferrer: false,
      sku: this.props.match.params.sku,
      roomData: null,
      roomOffers: {},
      loading: true,
      roomName: "",
      existentMatchData: [],
      matchId: "",
      roomId: "",
      unity: "",
      localTeamId: "",
      localTeamName: "",
      visitorTeamId: "",
      visitorTeamName: "",
      matchName: "Cargando...",
      makingOffer: false,
      unableToOffer: false,
      scoreOfferFor: "",
      roomTotal: 0,
      myOffers: 0,
      fullMatchDate: new Date(),
      offersByPlayer: [],
      showRoomReport: false,
    };

    this.getRoomData = this.getRoomData.bind(this);
    this.getRoomOffers = this.getRoomOffers.bind(this);
    this.getMatchName = this.getMatchName.bind(this);
    this.getLocalTeamName = this.getLocalTeamName.bind(this);
    this.getVisitorTeamName = this.getVisitorTeamName.bind(this);
    this.drawScoreGrid = this.drawScoreGrid.bind(this);
    this.makeOffer = this.makeOffer.bind(this);
    this.calculateRoomTotalOffer = this.calculateRoomTotalOffer.bind(this);
    this.reload = this.reload.bind(this);
    this.seeMatchDate = this.seeMatchDate.bind(this);
    this.copyRoomLink = this.copyRoomLink.bind(this);
    this.seePlayersNameList = this.seePlayersNameList.bind(this);
    this.seeRoomReport = this.seeRoomReport.bind(this);
    this.showSubastaRoomHelp = this.showSubastaRoomHelp.bind(this);
    this.makeRoomReport = this.makeRoomReport.bind(this);
    this.openRoomReport = this.openRoomReport.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("userData")) {
      let data = JSON.parse(localStorage.getItem("userData"));
      this.setState({ name: data.userData.name, userId: data.userData.id });
      //let data = localStorage.getItem("userData");
      console.log(data);
    } else {
      localStorage.setItem("room", window.location.href);
      this.setState({ previewRoom: true });
    }
    window.history.pushState("home", "subasta", "/home");
    window.onpopstate = function (event) {
      window.history.go();
    };
    console.log(this.props.match.params);
    this.getRoomData();
    if (this.state.makeOffer) {
      this.setState({ makeOffer: false });
    }
  }

  getRoomData() {
    let postData = {
      sku: this.state.sku,
    };
    PostData("getRoomData", postData).then((result) => {
      let responseJson = result;
      this.setState({ roomData: responseJson.roomData }, () => {
        console.log(this.state.roomData);
        let data = this.state.roomData[0];
        console.log(data);
        this.setState(
          {
            roomName: data.name,
            matchId: data.match_id,
            unity: data.unity,
            roomId: data.id,
            status: data.status,
          },
          () => {
            console.log(this.state.roomId);
            this.getRoomOffers();
          }
        );
      });
      this.setState({ loading: false });
    });
  }

  getRoomOffers() {
    let postData = {
      roomId: this.state.roomId,
    };
    PostData("getRoomOffers", postData).then((result) => {
      let responseJson = result;
      console.log(responseJson);
      let roomOfferslistRaw = responseJson.roomOffers;
      console.log(roomOfferslistRaw);
      let id = [];
      let playersNameList = [];
      for (let key = 0; key < roomOfferslistRaw.length; key++) {
        console.log(key + " : " + roomOfferslistRaw[key].id);
        id.push(Number(roomOfferslistRaw[key].id));
        playersNameList.push(roomOfferslistRaw[key].name);
      }
      console.log(id);
      console.log(playersNameList);
      let offersByPlayersList = [];
      const playersNameListUnified = new Set(playersNameList);
      let playersUnifiedNameListArray = [...playersNameListUnified];
      console.log(playersUnifiedNameListArray);
      this.setState({ playersNameList: playersUnifiedNameListArray }, () =>
        console.log("listado de jugadores: ", this.state.playersNameList)
      );

      for (let i = 0; i < id.length; i++) {
        offersByPlayersList.push({ id: id[i], offers: 0 });
        for (let k = 0; k < id.length; k++) {
          if (id[i] === id[k]) {
            offersByPlayersList[i].offers += 1;
          }
        }
        console.log(offersByPlayersList);
      }
      let prepareArray = function (arr) {
        return arr.map(function (elem) {
          if (typeof elem === "object" && elem !== null) {
            return JSON.stringify(elem);
          } else {
            return elem;
          }
        });
      };
      let set = new Set([...prepareArray(offersByPlayersList)]);
      let finalOffersByPlayersList = [...set];
      //console.log(typeof finalOffersByPlayersList);
      console.log(`total de jugadores: ${finalOffersByPlayersList.length}`);
      if (finalOffersByPlayersList.length >= 100) {
        this.setState({ roomIsFull: true });
      }

      this.setState({ offersByPlayer: finalOffersByPlayersList }, () =>
        console.log("total de ofertas por jugador:", this.state.offersByPlayer)
      );

      var keys = Object.keys(finalOffersByPlayersList);
      for (var i = 0; i < keys.length; i++) {
        let obj = JSON.parse(finalOffersByPlayersList[keys[i]]);

        if (obj.id === parseInt(this.state.userId)) {
          this.setState({ myOffers: obj.offers });
        }
      }

      let roomOffersList = {};
      for (let key in roomOfferslistRaw) {
        //console.log(roomOfferslistRaw[key].cell);
        let cellName = roomOfferslistRaw[key].cell;
        roomOffersList[cellName] = {
          playerId: roomOfferslistRaw[key].id,
          player: roomOfferslistRaw[key].name,
          offer: roomOfferslistRaw[key].bet,
        };
      }
      //console.log("aqui va el nuevo objeto");
      //console.log(roomOffersList);
      this.setState({ roomOffers: roomOffersList }, () => {
        console.log(
          "guardamos en el estado el objeto OfferList: ",
          this.state.roomOffers
        );
        this.getMatchName();
      });
    });
  }

  getMatchName() {
    let postData = { match_id: this.state.matchId };
    PostData("getExistentMatchData", postData).then((result) => {
      let responseJson = result;
      this.setState(
        { existentMatchData: responseJson.existentMatchData },
        () => {
          let matchDataObj = this.state.existentMatchData[0];
          console.log(matchDataObj);
          this.setState(
            {
              matchName: matchDataObj.name,
              localTeamId: matchDataObj.local_id,
              visitorTeamId: matchDataObj.visitor_id,
              matchDate: matchDataObj.date,
              matchHour: matchDataObj.hour,
              matchScoreLocal: matchDataObj.local,
              matchScoreVisitor: matchDataObj.visitor,
            },
            () => {
              console.log(
                `los datos del partido son: ${this.state.matchName}, ${this.state.localTeamId}, ${this.state.visitorTeamId}`
              );
              this.getLocalTeamName();
              this.getVisitorTeamName();
              let dateNow = new Date();
              console.log(dateNow);
              let fullMatchDateRaw =
                this.state.matchDate + "T" + this.state.matchHour + ":00.000Z";
              console.log(fullMatchDateRaw);
              let fullMatchDate = new Date(fullMatchDateRaw);
              console.log(dateNow > fullMatchDateRaw);
              const arrayMatchDate = this.state.matchDate.split("-");
              const arrayMatchHour = this.state.matchHour.split(":");
              console.log("fecha array:", arrayMatchDate);
              const testDate = new Date(
                arrayMatchDate[0],
                arrayMatchDate[1] - 1,
                arrayMatchDate[2],
                arrayMatchHour[0],
                arrayMatchHour[1],
                0,
                0
              );
              console.log(`fecha de ejemplo: ${testDate}`);
              this.setState({ fullMatchDate: testDate }, () =>
                console.log(fullMatchDate)
              );
              console.log(dateNow - testDate);
              if (dateNow > testDate) {
                if (this.state.status !== "4") {
                  this.setState({ status: "2" }, () =>
                    console.log("estado actualizado")
                  );
                }
                this.setState({ matchEnded: true }, () =>
                  console.log("El partido ya pasó")
                );
              }
            }
          );
        }
      );
    });
  }

  getLocalTeamName() {
    let postData = { teamId: this.state.localTeamId };
    PostData("getTeamName", postData).then((result) => {
      let responseJson = result;
      this.setState({ localTeamName: responseJson.teamName[0].name }, () => {
        console.log(this.state.localTeamName);
      });
    });
  }

  getVisitorTeamName() {
    let postData = { teamId: this.state.visitorTeamId };
    PostData("getTeamName", postData).then((result) => {
      let responseJson = result;
      this.setState({ visitorTeamName: responseJson.teamName[0].name }, () => {
        console.log(this.state.visitorTeamName);
      });
    });
  }

  makeOffer(score) {
    if (this.state.status === "4") {
      Swal.fire({
        title: "Partido cerrado",
        showConfirmButton: true,
        confirmButtonText: "Ver Cuentas",
        confirmButtonColor: "#16A64A",
        position: "top",
      });
    } else {
      if (this.state.matchEnded) {
        Swal.fire({
          title: "Partido en curso",
          text: `Espera los resultados`,
          showConfirmButton: true,
          confirmButtonText: "Entendido",
          confirmButtonColor: "#16A64A",
          position: "top",
        });
      } else {
        if (this.state.myOffers < 5) {
          this.setState({ makingOffer: true, scoreOfferFor: score }, () => {
            console.log(`oferta para: ${this.state.scoreOfferFor} `);
          });
        } else {
          this.setState({ unableToOffer: true }, () => {
            console.log("no puedes hacer más ofertas");
          });
        }
      }
    }
  }

  drawScoreGrid = () => {
    let scoreGridArray = [];
    for (let l = 0; l < 6; l++) {
      for (let v = 0; v < 6; v++) {
        let scoreClass = "score-box scoreL" + l + "V" + v;
        let scoreId = "L" + l + "V" + v;
        let celldataRaw = this.state.roomOffers[scoreId];
        let celldata = { ...celldataRaw };
        //console.log(celldata.player);
        let offer = Number(celldata.offer);
        let drawOfferGrid;
        if (offer) {
          drawOfferGrid = (
            <React.Fragment>
              <div className="subasta-scoreOffering-offerGrid-container">
                {formatter.format(offer)}
                <div className="subasta-scoreOffering-offerGrid-OfferPlayerName">
                  {celldata.player.substring(0, 10)}
                </div>
              </div>
            </React.Fragment>
          );
        }

        scoreGridArray.push(
          <div
            className={scoreClass}
            key={scoreId}
            onClick={() => {
              this.makeOffer(scoreId);
            }}
          >
            {drawOfferGrid}
          </div>
        );
      }
    }
    return scoreGridArray;
  };

  calculateRoomTotalOffer() {
    var roomTotal = 0;
    for (let l = 0; l < 6; l++) {
      for (let v = 0; v < 6; v++) {
        let scoreId = "L" + l + "V" + v;
        let celldataRaw = this.state.roomOffers[scoreId];
        let celldata = { ...celldataRaw };
        let offer = Number(celldata.offer);
        if (offer) {
          roomTotal += offer;
        }
      }
    }
    return roomTotal;
  }

  reload() {
    window.history.pushState("room", "subasta", `/rooms/${this.state.sku}`);
    window.history.go();
  }

  seeMatchDate() {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timezone: "America/Bogota",
      timeZoneName: "short",
    };
    const date = new Date(this.state.fullMatchDate);
    Swal.fire({
      title: "Fecha del Partido",
      text: date.toLocaleString(undefined, options),
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  seePlayersNameList() {
    const nameList = this.state.playersNameList;
    let html = "";
    nameList.map((name) => {
      html += "<p>" + name + "</p>";
      return html;
    });
    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 85,
      imageHeight: 130,
      title: "Jugadores en la Sala",
      html: html,
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  copyRoomLink() {
    let copyText = `¡Hey!, te invito a jugar ${this.state.matchName} en Subasta Futbolera. Ingresa a: https://app.subastafutbolera.com/rooms/${this.state.sku} y que gane el mejor!`;
    let tempInput = document.createElement("INPUT");
    tempInput.setAttribute("type", "text");
    tempInput.setAttribute("id", "textToCopy");
    tempInput.setAttribute("value", copyText);
    document.body.appendChild(tempInput);
    let element = document.getElementById("textToCopy");
    console.log(element);
    element.select();
    document.execCommand("copy");
    Swal.fire({
      imageUrl: tomyPopUp,
      imageWidth: 85,
      imageHeight: 130,
      title: "Listo!",
      text: `El enlace a la sala está copiado, compártelo en tus redes favoritas`,
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  showSubastaRoomHelp() {
    Swal.fire({
      imageUrl: ayudaSalaSubasta,
      html:
        "<ul><li>Cada casilla representa un marcador</li><li>Escoge el marcador y asigna la oferta</li><li>La cantidad de jugadores por casilla depende de la sala de juego</li><li>la sala se cierra cuando inicia el partido</li><br><li><strong>Subasta futbolera es una herramienta de entretenimiento, no maneja dinero ni da premios</strong></li></ul>",
      showConfirmButton: true,
      confirmButtonText: "Entendido",
      confirmButtonColor: "#16A64A",
      position: "top",
    });
  }

  makeRoomReport() {
    let roomWinnerName = "";
    let report = "";
    let roomWinnerId = "";

    let playersList = this.state.roomOffers;
    delete playersList.isPlayer;
    delete playersList.isOwner;
    let playersListSorted = {};
    Object.keys(playersList).forEach(function (key) {
      playersListSorted[key] = playersList[key];
    });
    console.log(playersListSorted);
    let scoresListTitles = Object.keys(playersListSorted);
    let titlesA = scoresListTitles.map((score) => score.replace("L", "", "gi"));
    let titlesArray = titlesA.map((score) => score.replace("V", " - ", "gi"));
    console.log(titlesArray);
    let namesArray = [];
    Object.keys(playersListSorted).forEach(function (key) {
      console.log(key, playersListSorted[key].player);
      namesArray.push(playersListSorted[key].player);
    });
    console.log(namesArray);
    let offersArray = [];
    Object.keys(playersListSorted).forEach(function (key) {
      console.log(key, playersListSorted[key].offer);
      offersArray.push(playersListSorted[key].offer);
    });
    console.log(offersArray);

    const arrayOfPlayersList = Object.keys(playersListSorted).map((key) => {
      return playersListSorted[key];
    });
    console.log(arrayOfPlayersList);
    if (roomWinnerId != 0) {
      console.log(roomWinnerId);

      let winnerOffers = arrayOfPlayersList.filter((el) => {
        return el.playerId === roomWinnerId;
      });
      console.log(winnerOffers);

      let totalOfferWinner = 0;
      for (let i = 0; i < winnerOffers.length; i++) {
        console.log(winnerOffers[i].offer);
        let offerNumber = parseInt(winnerOffers[i].offer);
        console.log(offerNumber);
        totalOfferWinner += offerNumber;
      }

      console.log(`${roomWinnerName} ofertó un total de: ${totalOfferWinner}`);
    }

    // get records by player
    console.log(arrayOfPlayersList);
    const filteredPlayerList = arrayOfPlayersList.reduce((acc, current) => {
      const x = acc.find((item) => item.playerId === current.playerId);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    console.log(filteredPlayerList);
    for (let j = 0; j < filteredPlayerList.length; j++) {
      console.log(filteredPlayerList[j].playerId);
      let postData = {
        client_user_id: filteredPlayerList[j].playerId,
        room_id: this.state.roomId,
      };
      if (postData) {
        PostData("getRecordsByPlayer", postData).then((result) => {
          console.log(result.recordsByPlayer);
          let recordsByPlayer = result.recordsByPlayer;

          let recordByPlayerOffersSubtotal = 0;
          for (let k = 0; k < recordsByPlayer.length; k++) {
            console.log(recordsByPlayer[k].my_bet);
            recordByPlayerOffersSubtotal += parseInt(recordsByPlayer[k].my_bet);
            report += `<div class="report-text-container"><div class="report-text">${
              recordsByPlayer[k].name
            }</div><div class="report-text">${
              recordsByPlayer[k].score
            }</div><div class="report-text">${formatter.format(
              recordsByPlayer[k].my_bet
            )}</div></div>`;
          }
          report += `<div class="subtotal">Subtotal: ${formatter.format(
            recordByPlayerOffersSubtotal
          )}</div><hr>`;
          console.log(report);
          console.log(`el subtotal es: ${recordByPlayerOffersSubtotal}`);
          Swal.fire({
            title: "Reporte de la Sala",
            html: report,
            showConfirmButton: true,
            confirmButtonText: "Entendido",
            confirmButtonColor: "#16A64A",
            position: "top",
          });
        });
      }
    }
  }

  seeRoomReport() {}

  openRoomReport() {
    if (this.state.status === "4") {
      this.setState({ showRoomReport: true });
    } else {
      if (this.state.status === "1") {
        Swal.fire({
          title: "Juega y gana!",
          text:
            "El partido no ha empezado, puedes seguir ofertando, cuando termine, aquí veras los resultados.",
          showConfirmButton: true,
          confirmButtonText: "Entendido",
          confirmButtonColor: "#16A64A",
          position: "top",
        });
      }
    }
  }

  render() {
    let showReport;
    if (this.state.showRoomReport === true) {
      showReport = (
        <RoomReport
          roomId={this.state.roomId}
          roomStatus={this.state.status}
          roomOffers={this.state.roomOffers}
          localTeamName={this.state.localTeamName}
          matchScoreLocal={this.state.matchScoreLocal}
          visitorTeamName={this.state.visitorTeamName}
          matchScoreVisitor={this.state.matchScoreVisitor}
          sku={this.state.sku}
        />
      );
    }
    let roomTotal = this.calculateRoomTotalOffer();
    let roomTotalFormated = formatter.format(roomTotal);
    let makeOffer;
    if (this.state.makingOffer) {
      console.log("score offer for = ", this.state.scoreOfferFor);
      let scoreId = this.state.scoreOfferFor;
      console.log("scoreOfferData para pasar:", this.state.roomOffers[scoreId]);
      let scoreOfferData;
      if (this.state.roomOffers[scoreId] !== undefined) {
        scoreOfferData = this.state.roomOffers[scoreId];
      } else {
        scoreOfferData = {
          offer: 0,
          playerId: "none",
          player: "Marcador Libre",
        };
      }
      makeOffer = (
        <ScoreOffering
          sku={this.state.sku}
          scoreId={scoreId}
          localTeam={this.state.localTeamName}
          visitorTeam={this.state.visitorTeamName}
          unity={this.state.unity}
          roomId={this.state.roomId}
          scoreOfferData={scoreOfferData}
        />
      );
    }
    let previewRoomActive;
    if (this.state.previewRoom) {
      previewRoomActive = (
        <div className="room-preview-container">
          <div className="room-preview-message-container">
            <div className="room-preview-text">
              <p>¡Te estamos esperando!, ingresa para poder jugar.</p>
            </div>
            <div>
              <Link className="btn btn-primary centered" to={"/"}>
                Ingresar
              </Link>
            </div>
          </div>
        </div>
      );
    }
    let roomIsFull;
    if (this.state.roomIsFull) {
      roomIsFull = (
        <div className="room-preview-container">
          <div className="room-preview-message-container">
            <div className="room-preview-text">
              <p>
                ¡Ohh!, Este partido es tan bueno que la sala se llenó en un
                segundo.
              </p>
              <p>
                Compra o usa una llave. Desbloquea la cantidad de jugadores y
                más funcionalidades avanzadas
              </p>
            </div>
            <div className="btn btn-primary centered">
              <Link to={"/"}>Comprar / Usar Llave</Link>
            </div>
          </div>
        </div>
      );
    }
    let unableToOffer;
    if (this.state.unableToOffer) {
      unableToOffer = (
        <div className="room-preview-container">
          <div className="room-preview-message-container">
            <div className="room-preview-text">
              <p>¡Ohh!, Ya no puedes hacer más ofertas.</p>
              <p>
                No te pongas triste, si alguien te quita una oferta podrás
                volver a ofertar. Recuerda que solo puedes ofertar máximo hasta
                por cinco (5) marcadores distintos en una sala.
              </p>
            </div>
            <div className="btn btn-danger centered" onClick={this.reload}>
              volver
            </div>
          </div>
        </div>
      );
    }
    let showIconReports;
    if (this.state.status === "4") {
      showIconReports = (
        <img src={roomIconReportsAlert} alt="Reportes de la Sala" />
      );
    } else {
      showIconReports = <img src={roomIconReports} alt="Reportes de la Sala" />;
    }
    // if (this.state.redirectToReferrer) {
    //   return <Redirect to={"/"} />;
    // }
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          {unableToOffer}
          {makeOffer}
          {previewRoomActive}
          {roomIsFull}
          <NavSup />
          <AddSystem />
          <div className="subasta-room-screen-container">
            <div className="subasta-create-rooms-btn-container mobile">
              <div className="btn btn-danger-small centered">
                <Link to={"/"}>X</Link>
              </div>
            </div>
            <h1>{this.state.roomName}</h1>
            <h2>({this.state.matchName})</h2>
            <div className="subasta-room-screen-options-menu-container">
              <div
                className="subasta-room-screen-options-menu-item"
                onClick={this.seeMatchDate}
              >
                <img src={roomIconDate} alt="Fecha del Partido" />
              </div>
              <div
                className="subasta-room-screen-options-menu-item"
                onClick={this.copyRoomLink}
              >
                <img src={roomIconShare} alt="Invita a tus amigos" />
              </div>
              <div
                className="subasta-room-screen-options-menu-item"
                onClick={this.seePlayersNameList}
              >
                <img src={roomIconUsers} alt="Jugadores en la Sala" />
              </div>
              <div
                className="subasta-room-screen-options-menu-item"
                onClick={this.openRoomReport}
              >
                {showIconReports}
                {showReport}
              </div>
            </div>
            <div className="subasta-room-screen-grid-container">
              <div className="visitorTeam">
                <div className="outer">
                  <div className="inner rotate">
                    GOLES {this.state.visitorTeamName}
                  </div>
                </div>
              </div>
              <div className="localTeam">GOLES {this.state.localTeamName}</div>
              <div className="score-grid-container">
                <div className="subasta-room-screen-score-grid">
                  <div className="helpIcon" onClick={this.showSubastaRoomHelp}>
                    <img src={subastaHelpIcon} alt="Ayuda" />
                  </div>
                  <div className="scoreTitleX scoreTitleX0">0</div>
                  <div className="scoreTitleX scoreTitleX1">1</div>
                  <div className="scoreTitleX scoreTitleX2">2</div>
                  <div className="scoreTitleX scoreTitleX3">3</div>
                  <div className="scoreTitleX scoreTitleX4">4</div>
                  <div className="scoreTitleX scoreTitleX5">5</div>
                  <div className="scoreTitleY scoreTitleY0">0</div>
                  <div className="scoreTitleY scoreTitleY1">1</div>
                  <div className="scoreTitleY scoreTitleY2">2</div>
                  <div className="scoreTitleY scoreTitleY3">3</div>
                  <div className="scoreTitleY scoreTitleY4">4</div>
                  <div className="scoreTitleY scoreTitleY5">5</div>
                  {this.drawScoreGrid()}
                </div>
              </div>
            </div>
            <div className="subasta-room-screen-total-container">
              <div className="subasta-room-screen-total-value">
                Total Sala:{" "}
                <span className="subasta-room-screen-total-value-number">
                  {roomTotalFormated}
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Room;
