import React, { Component } from "react";
import { PostData } from "../../services/PostData";
import { Redirect } from "react-router-dom";

import "../../components/Welcome/Welcome.css";
import "../../components/Signup/Signup.css";
import logoAppSmall from "../../images/logoAppSmall.png";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      email: this.props.location.state.email,
      redirectToReferrer: false
    };
    this.doPasswordChange = this.doPasswordChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  doPasswordChange() {
    if (this.state.newPassword === this.state.confirmNewPassword) {
      console.log("llamar a la api y cambiar el pass");
      PostData("changePassword", this.state).then(result => {
        let responseJson = result;
        if (responseJson.userData) {
          localStorage.setItem("userData", JSON.stringify(responseJson));
          localStorage.setItem("userData", "");
          localStorage.clear();
          this.setState({ redirectToReferrer: true });
        } else alert(result.error);
      });
    } else {
      alert("error al confirmar el password");
    }
  }
  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/"} />;
    } else {
      return (
        <React.Fragment>
          <div className="signup-container">
            <div className="img-desktop-container">
              <div className="img-desktop-wrap"></div>
            </div>
            <div className="signup-form-container">
              <div className="signup-header-container">
                <div className="logoApp">
                  <img src={logoAppSmall} alt="Subasta Futbolera" />
                </div>
                <div className="signup-title">
                  <h2>Cambio de contraseña</h2>
                </div>
              </div>
              <div className="signup-form">
                <label htmlFor="">NUEVA CONTRASEÑA</label>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Contraseña"
                  onChange={this.onChange}
                />

                <label htmlFor="">REPETIR CONTRASEÑA</label>
                <input
                  type="password"
                  name="confirmNewPassword"
                  placeholder="Contraseña"
                  onChange={this.onChange}
                />
                <input
                  type="submit"
                  className="btn btn-primary centered"
                  value="CAMBIAR CONTRASEÑA"
                  onClick={this.doPasswordChange}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default ChangePassword;
