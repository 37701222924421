import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./CreateRoomMenuComponent.css";
import CreateSubastaRoom from "../../components/CreateSubastaRoom/CreateSubastaRoom";

import closeCreateRoomsIcon from "../../images/close-createRooms-btn-mobile.png";
import createSubastaIcon from "../../images/create-subasta-icon.png";
import createMultipleIcon from "../../images/createmultipleIcon.png";
import createCampeonatoIcon from "../../images/createCampeonatoIcon.png";

import pujaIcon from "../../images/pujaIcon.jpg";
import reportesSalaIcon from "../../images/reportesSalaIcon.jpg";
import tableroDigitalIcon from "../../images/tableroDigitalIcon.jpg";
import trofeoIcon from "../../images/trofeoIcon.jpg";

class CreateRoomMenuComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewCreateSubastaRoom: false,
      viewSubastaRoomInfo: false
    };
    this.openCreateSubastaRoomComponent = this.openCreateSubastaRoomComponent.bind(
      this
    );
    this.viewSubastaInfo = this.viewSubastaInfo.bind(this);
  }
  componentDidMount() {
    window.history.pushState("home", "subasta", "/home");
    window.onpopstate = function(event) {
      window.history.go();
    };
  }
  openCreateSubastaRoomComponent(e) {
    //var body = document.getElementsByClassName("subasta-dashboard-container");
    //body[0].style.overflow = "hidden";
    this.setState({ viewCreateSubastaRoom: true });
  }
  viewSubastaInfo(e) {
    this.setState({ viewSubastaRoomInfo: true });
  }
  render() {
    let createSubastaRoom = this.state.viewCreateSubastaRoom;
    let subastaInfo;
    if (this.state.viewSubastaRoomInfo) {
      subastaInfo = (
        <div className="room-info">
          <div className="room-info-separator"></div>
          <div className="room-info-title">Sala Subasta</div>
          <div className="room-info-description">
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={tableroDigitalIcon} alt="Tablero Digital" />
              </div>
              <div className="room-info-description-text">
                <p>
                  Tablero digital con casillas que representan los marcadores
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={pujaIcon} alt="Puja por tu marcador" />
              </div>
              <div className="room-info-description-text">
                <p>
                  Obtén los marcadores pujando por ellos, aumentando su valor
                </p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={reportesSalaIcon} alt="Reportes automáticos" />
              </div>
              <div className="room-info-description-text">
                <p>Los cálculos y reportes de la sala los hacemos por ti</p>
              </div>
            </div>
            <div className="room-info-description-item">
              <div className="room-info-description-image">
                <img src={trofeoIcon} alt="El ganador se lleva todo" />
              </div>
              <div className="room-info-description-text">
                <p>
                  El jugador con el marcador ganador se lleva todas las monedas
                  de la sala
                </p>
              </div>
            </div>
          </div>
          <div className="room-info-btn-container">
            <div
              className="btn btn-primary room-info-btn"
              onClick={this.openCreateSubastaRoomComponent}
            >
              CREAR SALA
            </div>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="subasta-close-create-roomsIcon desktop">
          <img src={closeCreateRoomsIcon} alt="" />
        </div>
        <div className="subasta-create-rooms-menu">
          <div className="subasta-create-rooms-btn-container mobile">
            <div className="btn btn-danger-small centered">
              <Link to={"/"}>X</Link>
            </div>
          </div>
          <h1>CREAR SALA</h1>
          <div className="subasta-create-rooms-items-container">
            <div
              className="subasta-create-rooms-item"
              onClick={this.viewSubastaInfo}
            >
              <div className="subasta-create-rooms-icon">
                <img src={createSubastaIcon} alt="Crea Sala tipo subasta" />
              </div>
              <div className="subasta-create-rooms-title">SUBASTA</div>
            </div>
            <div className="subasta-create-rooms-item">
              <div className="subasta-create-rooms-icon">
                <img
                  className="iconDisabled"
                  src={createMultipleIcon}
                  alt="Crea Sala tipo Múltiple"
                />
              </div>
              <div className="subasta-create-rooms-title">MÚLTIPLE</div>
            </div>
            <div className="subasta-create-rooms-item">
              <div className="subasta-create-rooms-icon">
                <img
                  className="iconDisabled"
                  src={createCampeonatoIcon}
                  alt="Crea Sala tipo Campeonato"
                />
              </div>
              <div className="subasta-create-rooms-title">CAMPEONATO</div>
            </div>
          </div>
          {subastaInfo}
        </div>
        {createSubastaRoom ? (
          <div className="subasta-createRoomsMenu-container">
            <CreateSubastaRoom />
          </div>
        ) : (
          console.log("invisible")
        )}
      </React.Fragment>
    );
  }
}

export default CreateRoomMenuComponent;
